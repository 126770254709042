.cartitems{
    margin: 100px 10%;
}
.cartitems hr{
    height: 2px;
    background: #e2e2e2;
    border: 0;
}
.cartitems-format-main{
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 50px;
    padding: 20px 0px;
    color: #454545;
    font-size: 16px;
    font-weight: 600;
}
.cartitems-format{
    
    font-size: 16px;
    font-weight: 500;
}
.cartitems-product-icon{
    height: 55px;
}
.cartitems-remove-icon{
    width: 12px;
    margin: 0px 30px;
    cursor: pointer;
}
.cartitems-quantity{
    width: 55px;
    height: 45px;
    border: 2px solid #ebebeb;
    background: #fff;
}
.cartitems-down{
    display: flex;
    margin: 100px 0px;
}
.cartitems-total{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 200px;
    gap: 40px;
}
.cartitems-total-item{
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
}
.cartitems-total button{
    width: 220px;
    height: 50px;
    outline: none;
    border: none;
    background: #ff5a5a;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.cartitems-promocode{
    flex: 1;
    font-size: 16px;
    font-weight: 500;
}
.cartitems-promocode p{
    color: #555;
}
.cartitems-promobox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    margin-top: 15px;
    height: 50px;
    background: #eaeaea;
}
.cartitems-promobox input{
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    flex: 1;
    height: 100%;
    padding-left: 20px;
}
.cartitems-promobox button{
    width: 130px;
    height: 100%;
    font-size: 16px;
    background: black;
    color: white;
    cursor: pointer;
}
@media(max-width:1280px){
    .cartitems{
        margin: 60px 8%;
    }
    .cartitems-format-main{
        grid-template-columns: 0.5fr 3fr 0.5fr 0.5fr 0.5fr 0.5fr;
        gap: 20px;
        padding: 15px 0px;
        font-size: 15px;
    }
    .cartitems-format{
        font-size: 15px;
    }
    .carticon-product-icon{
        height: 50px;
    }
    .cartitems-remove-icon{
        margin: auto;
    }
    .cartitems-quantity{
        width: 40px;
        height: 30px;
    }
    .cartitems-down{
        margin: 80px 0px;
        flex-wrap: wrap;
        gap: 40px;
    }
    .cartitems-total{
        margin: 0;
        min-width: 300px;
    }
    .cartitems-total button{
        max-width: 200px;
        height: 45px;
        font-size: 13px;
    }
    .cartitems-promobox{
        width: 90%;
        max-width: 500px;
    }
    
}
@media(max-width:500px){
    .cartitems-format-main{
       display: none;
    }
    .cartitems-format{
        display: grid;
    }
    .cartitems-format-main{
        grid-template-columns: 0.5fr 3fr 0.5fr;
        gap: 10px;
    }
}