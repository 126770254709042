.newsletter{
    width: 90%;
    max-width: 1200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 50px 8%;
    margin-bottom: 100px;
    background: linear-gradient(180deg, #fff1d6 0%, #e1ffea22 60%);
    gap: 20px;
}

.newsletter h1{
    color: #454545;
    font-size: 45px;
    font-weight: 600;
}

.newsletter p{
    color: #454545;
    font-size: 18px;
}

.newsletter div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 95%;
    max-width: 650px;
    height: 60px;
    border-radius: 80px;
    border: 1px solid #e3e3e3;
}

.newsletter input{
    flex: 1;
    padding-left: 30px;
    border: none;
    outline: none;
    color: #616161;
    font-family: Poppins;
    font-size: 16px;
}

.newsletter div button{
    width: 180px;
    height: 60px;
    border-radius: 80px;
    background: black;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

/* ----------- media query--------- */

@media(max-width:1280px){
    .newsletter h1{
        font-size: 35px;
    } 
}

@media(max-width:900px){
    .newsletter h1{
        font-size: 30px;
    }
    .newsletter{
        gap: 10px;
    }
   .newsletter div{
        width: 95%;
        height: 50px;
        margin-top: 10px;
   }
   .newsletter input{
        padding-left: 20px;
    }
    .newsletter div button{
        width: 100px;
        height: 50px;
        border-radius: 25px;
        font-size: 14px;
    }
}
@media(max-width:500px){
    .newsletter{
        padding: 50px 5%;
    }
    .newsletter div{
        width: 100%;
    }
    .newsletter div button{
        font-size: 12px;
    }
}